<template lang="pug">
.wrapper
    .row.justify-between.q-mt-md.q-mb-sm.q-mx-md.items-center
        router-link.font-24.text-primary.text-weight-bold.decoration-no(
            :to="city ? `/${city?.slug}/articles` : '/articles'"
            title="Статьи" itemprop="text") Статьи
        router-link.font-24.text-weight-bold.text-positive.line-height-22.link(:to="city ? `/${city?.slug}/articles` : '/articles'" title="Все статьи")
            | Все статьи  
    q-scroll-area(
        :thumb-style="thumbStyle"
        :bar-style="thumbStyle"
        :style="`height: 40px;`").q-my-md
        .row.no-wrap.q-pl-md
            .tag.text-weight-medium.cursor-pointer.q-mr-sm.text-grey-9.border-grey-9(
                v-for="(tag, index) in articlesTags.tags"
                :key="index"
                @click="setTag(tag.id)")
                | {{ tag.name }}
    q-scroll-area.full-width.articles(
        :thumb-style="{display: 'none'}")
        .row.no-wrap
            router-link.link.column.text-primary.article.q-mr-md(
                v-if="articles.results.length"
                v-for="(item, index) of articles.results"
                :key="index"
                :style="index < 4 ? '' : 'display: none;'"
                :to="city ? `/${city?.slug}/article/${item.slug}` : `article/${item.slug}`"
                :title="`${item.slug}`")
                img.article__img(
                    width="100%"
                    :src="item.imagePreviewCdn"
                    style="object-fit: cover;object-position: 50% 50%; "
                    loading="lazy"
                )
                .font-16.line-height-24.text-weight-bold.q-mt-md.article__name(
                    style="overflow: hidden;"
                )
                    | {{ item.title }}
                .line-height-20.q-mt-sm.article__title
                    | {{ formatPreviewText(item.previewText) }}
                .row.justify-between.q-mt-sm.text-grey-9
                    | {{ arcticleDate(item.dateOfPublication) }}
                    .row
                        q-icon(
                            name="etabl:chat-round"
                            size="20px"
                            color="grey-9"
                        ).q-mr-sm
                        span {{ item.totalReviews }}
                        q-icon(
                            name="etabl:eye"
                            size="20px"
                            color="grey-9"
                        ).q-mr-sm.q-ml-lg
                        span {{ item.totalViews }}


</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: 'ArticlesMobile',
    props: {
        articles: {
            default: () => null,
            required: true,
        },
    },
    setup(props) {
        const router = useRouter();

        const store = useStore();

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const articlesTags = computed(() => store.getters['advertisement/ARTICLES_TAGS']);

        // стили для полосы прокрутки
        const thumbStyle = computed(() => {
            return {
                width: 0,
                opacity: 0,
            };
        });

        const showFull = ref(false);

        const goTo = (link) => {
            router.push(`/article/${link}`);
        };

        const arcticleDate = (value) => {
            let date = new Date(value);

            return date.toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        };

        const setTag = (value) => {
            router.push(city.value ? `/${city.value.slug}/articles?id=${value}` : `/articles?id=${value}`);
        };
        const formatPreviewText = (text) => {
            if (!text) return '';
            return text
                .replace(/\r\n|\n/g, ' ')
                .replace(/\s+/g, ' ')
                .trim();
        };


        return {
            goTo,
            showFull,
            city,
            articlesTags,
            thumbStyle,
            arcticleDate,
            setTag,
            formatPreviewText,
        };
    },
};
</script>

<style lang="scss" scoped>
.article {
    min-width: 48%;

    &:first-child {
        margin-left: 16px;
    }

    &:nth-child(4) {
        padding-right: 16px;
    }

    &__img {
        height: calc((80vw - 15px) * 0.61);
        border-radius: 16px;
    }

    &__name {
        height: 48px;
    }

    &__title {
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }
}

.articles {
    height: calc((80vw - 15px) * 0.61 + 164px);
}

.tag {
    padding: 4px 16px;
    border-radius: 16px;
    height: 32px;
    white-space: nowrap;
}

</style>
